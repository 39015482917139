import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth"
import { getFirestore } from "firebase/firestore"

const firebaseConfig = {
  apiKey: "AIzaSyB--5rQz_1Au6x1wMYWNlWma5Y14txdp4s",
  authDomain: "reffi-c14a2.firebaseapp.com",
  projectId: "reffi-c14a2",
  storageBucket: "reffi-c14a2.appspot.com",
  messagingSenderId: "877995085941",
  appId: "1:877995085941:web:80aaa0431fec9e03f8a83c",
  measurementId: "G-1TCNBXPHFR"
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export const auth = getAuth(app);

export const db = getFirestore(app);