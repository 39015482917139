import React, { createContext, useContext, useEffect, useState, useMemo } from 'react';
import { auth, db } from 'config/firebase';
import { collection, doc, onSnapshot, query, where, getDocs, getDoc, DocumentData, Query, DocumentReference } from 'firebase/firestore';
import { Club, Invitation, Match, Referee, User, paths } from '@monorepo/shared';

interface ClubState {
  matches: Match[];
  invitations: Invitation[],
  club: Club | undefined,
  user: User | undefined,
  referees: Invitation[],
  userInvitation: Invitation | undefined,
  setMatches: React.Dispatch<React.SetStateAction<Match[]>>;
}

export async function  getDocuments(ref: Query<DocumentData, DocumentData>) {
  const data = await getDocs(ref);
  return data.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
  }));
}

export async function getDocument(ref: DocumentReference<DocumentData, DocumentData>) {
  const doc = await getDoc(ref);
  if (!doc.exists()) {
      return null;
  }
  return ({
      id: doc.id,
      ...doc.data()
  });
}

const ClubStateContext = createContext<ClubState | undefined>(undefined);

export const ClubStateProvider: React.FC<{ children: React.ReactNode, userId: string }> = ({ children, userId }) => {
  const [matches, setMatches] = useState<Match[]>([]);
  const [club, setClub] = useState<Club>();
  const [invitations, setInvitations] = useState<Invitation[]>([]);
  const [referees, setReferees] = useState<Invitation[]>([])
  const [user, setUser] = useState<User>()
  const [userInvitation, setUserInvitation] = useState<Invitation | undefined>(undefined)

  useEffect(() => {
    if (!userId) {
      setMatches([]);
      setClub(undefined);
      setInvitations([]);
      setReferees([]);
      setUser(undefined);
      setUserInvitation(undefined);
      return;
    }
    const userRef = doc(db, paths.clubUsers, userId);
    const unsubscribeUser = onSnapshot(userRef, async () => {
      setUser(await getDocument(userRef) as User);
    });

    return () => {
      unsubscribeUser();
    }
  }, [userId]);

  useEffect(() => {
    if (!userId) {
      return;
    }
    if (!user || !user.activeClub) {
      return;
    }
    const id = user.activeClub;
    const invitationRef = doc(db, paths.clubs, id, paths.invitations, userId);

    const unsubscribeInvitation = onSnapshot(invitationRef, async () => {
      setUserInvitation(await getDocument(invitationRef) as Invitation)
    })

    return () => {
      unsubscribeInvitation()
    };
  }, [user, userId]);

  useEffect(() => {
    if (!userId) {
      return;
    }
    if (!user || !user.activeClub || userInvitation?.status !== "accepted") {
      return;
    }

    const id = user.activeClub;
    const clubRef = doc(db, paths.clubs, id);
    const matchRef = collection(clubRef, paths.matches);
    const invitationRef = collection(clubRef, paths.invitations);

    const unsubscribeClub = onSnapshot(clubRef, async () => {
      setClub(await getDocument(clubRef) as Club);
    });

    const unsubscribeMatches = onSnapshot(matchRef, async () => {
      setMatches(await getDocuments(matchRef) as Match[]);
    });

    const unsubscribeInvitations = onSnapshot(invitationRef, async () => {
      const invitations = await getDocuments(invitationRef) as Invitation[];
      setInvitations(invitations);
      const referees = invitations.filter(invitation => invitation.role === "referee" && invitation.status === "accepted");
      setReferees(referees);
    });
  

    return () => {
      unsubscribeMatches();
      unsubscribeInvitations();
      unsubscribeClub();
    };
  }, [user, userId, userInvitation]);

  const contextValue = useMemo(() => ({
    matches,
    invitations,
    club,
    user,
    referees,
    userInvitation,
    setMatches
  }), [matches, invitations, club, user, referees, userInvitation, setMatches]);

  return (
    <ClubStateContext.Provider value={contextValue}>
      {children}
    </ClubStateContext.Provider>
  );
};

export const useClubState = (): ClubState => {
  const context = useContext(ClubStateContext);
  if (!context) {
    throw new Error('useClubState must be used within a ClubStateProvider');
  }
  return context;
};