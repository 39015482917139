import * as React from "react";
import { Stack, Typography, Link, TextField, Button, Box, Autocomplete, Select, MenuItem, FormControl, InputLabel, CircularProgress, LinearProgress, FormGroup, FormControlLabel, Checkbox, Modal, emphasize, IconButton } from "@mui/material";
import { ActiveClub, Colors, Invitation, paths } from "@monorepo/shared";
import { getDistricts, getClubs, calculatePrice, User as ClubUser, Club,  } from "@monorepo/shared";
import {createUserWithEmailAndPassword, sendPasswordResetEmail, signInWithEmailAndPassword, User} from "firebase/auth"
import { auth, db } from "config/firebase";
import { collection, doc, getDocs, setDoc } from "firebase/firestore";
import CloseIcon from '@mui/icons-material/Close';

async function createUser(user: User, clubUser: ClubUser,) {
  clubUser.id = user.uid;
  await setDoc(doc(db, paths.clubUsers, user.uid), clubUser);
}

async function createClub(user: User, club: Club) {
  await setDoc(doc(db, paths.clubs, club.id), club);
  const activeClub: ActiveClub = {
    id: club.id,
    clubName: club.clubName,
    clubType: club.clubType,
  }
  await setDoc(doc(db, paths.activeClubs, club.id), activeClub);
}

async function signInUser(user: ClubUser, password: string) {
  try {
    if(!user.email || !password) return
    await signInWithEmailAndPassword(auth, user.email, password)
  } catch (error) {
    console.log("Error signing in: ", error);
  }
}

async function signUp(clubUser: ClubUser, club: Club, password: string, isTrainer: boolean, clubTaken: boolean) {
  if (Object.values(clubUser).some(d => !d)) {    
    console.log("User is missing fields")  
    return;
  }
  const isNewClub = !isTrainer && !clubTaken;
  try {
    const user: User = (await createUserWithEmailAndPassword(auth, clubUser.email, password)).user;
    console.log(user)
    clubUser.id = user.uid;
    if (isNewClub) {
      if (club.id === "" || club.clubType === "" || club.clubName === "") {
        console.log("Club is missing fields"); 
        return
      }
      club.settings = {
        referees: 1,
        '3x15': 150,
        '3x20': 345,
        '3x25': 430,
        '2x40': 520,
      }

      await createClub(user, club);
    }

    const invitationsCollectionRef = collection(db, paths.clubs, club.id, paths.invitations);
    const invitationData: Invitation = {
      id: clubUser.id,
      name: clubUser.name,
      email: clubUser.email,
      phoneNumber: clubUser.phoneNumber,
      role: isNewClub ? "owner" : isTrainer ? "trainer" : "responsible",
      status: isNewClub ? "accepted" : "invited",
    }
    console.log("tjoho", invitationData)
    await setDoc(doc(invitationsCollectionRef, user.uid), invitationData)

    await createUser(user, clubUser);

  } catch (error) {
    console.error("Error Joinging or creating Club: ", error);
  }
}

const emptyUser = () => ({id: "0", phoneNumber: "", email: "", activeClub: "", name: "", settings: {}});

export const AuthDrawer = ({onClose, signIn = false}: {onClose: () => void, signIn?: boolean}) => {

  const [isTrainer, setIsTrainer] = React.useState(false);

  const [showSignIn, setShowSignIn] = React.useState(signIn)
  // id user id 0 to allow filled out to function, id will be replaced on account creation
  

  const [user, setUser] = React.useState<ClubUser>(emptyUser());
  const [club, setClub] = React.useState<Club>({id: "", clubName: "", clubType: "", settings: {}});
  const [password, setPassword] = React.useState("");
  const [repeatPassword, setRepeatPassword] = React.useState("");

  const [district, setDistrict] = React.useState("");
  const [districts, setDistricts] = React.useState<{value: string, label: string}[]>([]);

  const [clubs, setClubs] = React.useState<{value: string, label: string}[]>([]);
  const [takenClubs, setTakenClubs] = React.useState<{value: string, label: string}[]>([])
  
  const [clubTaken, setClubTaken] = React.useState<boolean>(false);
  const [price, setPrice] = React.useState(0);

  const [generalPolicy, setGeneralPolicy] = React.useState(false);
  const [integrity, setIntegrity] = React.useState(false);


  const [policy, setPolicy] = React.useState("");

  const filledOut = (
    Object.values(user).every(val => val !== "" ) && club.clubType !== ""  && club.id !== ""  && club.clubName !== "" && integrity && generalPolicy
  )
  React.useEffect(() => {
    (async () => {
      const clubRef = collection(db, paths.activeClubs);
      const clubs = (await getDocs(clubRef)).docs.map(doc => ({value: doc.id, label: doc.data()["clubName"]}))
      setTakenClubs(clubs || [])
    })()
  }, [])

  React.useEffect(() => {
    (async () => {
      setDistricts(await getDistricts(club.clubType) || [])
    })()
  }, [club.clubType])
  
  React.useEffect(() => {
    console.log(district)
    if (district === "") return;
    (async () => {
      setClubs(await getClubs(district, club.clubType) || [])
    })()
  }, [district, club.clubType])

  React.useEffect(() => {
    if (club.id === "") return;
    setPrice(0); 
    const isTaken = takenClubs.find(c => c.value === club.id) !== undefined;
    setClubTaken(isTaken);
    if (isTaken) return;
    (async () => {
      const newPrice = await calculatePrice(parseInt(club.id), club.clubType);
      setPrice(newPrice || 0);
    })();
  }, [club.id, club.clubType, takenClubs]);

  const handleClick = async () => {
    showSignIn ? await signInUser(user, password) : await signUp(user, club, password, isTrainer, clubTaken);
  }

  const resetPassword = async () => {
    sendPasswordResetEmail(auth, user.email);
  }

  return (
    <Stack spacing={2} sx={{ width: 450, maxWidth: "100vw", padding: 5, height: "100vh" }} role="presentation">
      <Button sx={{position: "absolute", right: 1, top: 2}} onClick={onClose}><CloseIcon/></Button>
      <Typography sx={{ textAlign: 'center' }}>
        {!showSignIn ? "Har du redan konto? " : "Saknar du konto?"}
        <span>
          <Link component="button" onClick={() => {setUser(emptyUser()); setPassword(""); setRepeatPassword(""); setShowSignIn(!showSignIn); }}>
            {!showSignIn ? "Logga in" : "Skapa konto"}
          </Link>
        </span>
      </Typography>
      {!showSignIn && <Button  variant="contained" onClick={() => setIsTrainer(!isTrainer)} >{isTrainer ? "Byt till klubb" : "Byt till tränare"}</Button>}
      <TextField  id="mail" label="Mail" variant="standard" value={user.email} onChange={e => setUser({...user, email: e.target.value})} />
      {!showSignIn && <Stack direction={"row"} spacing={3}>
        <TextField id="name" label="Namn" variant="standard" value={user.name} onChange={e => setUser({...user, name: e.target.value})}/>
        <TextField  id="phone" label="Telefon" variant="standard" onChange={e => setUser({...user, phoneNumber: e.target.value})}/>
      </Stack>}
      {<Stack direction={"row"} spacing={3}>
        <TextField fullWidth type='password' value={password} id="password" label="Lösenord" variant="standard" onChange={e => setPassword(e.target.value)}/>
        {!showSignIn && <TextField error={password !== repeatPassword} value={repeatPassword} helperText={password !== repeatPassword && "lösenord matchar inte"} fullWidth type='password' id="passwordRepeat" label="Repetera Lösenord" variant="standard" onChange={e => setRepeatPassword(e.target.value)}/>}
      </Stack>}
      {!showSignIn && 
        <Stack alignItems={"center"}>
          {!showSignIn && (
            <FormControl fullWidth variant="outlined" sx={{marginTop: 2}}>
              <InputLabel id="sport-label">Välj en sport</InputLabel>
              <Select
                id="sport"
                value={club.clubType}
                variant="standard"
                label="Välj en sport"
                labelId="sport-label"
                onChange={(e) => setClub({...club, clubType: e.target.value})}
              >
                <MenuItem value={"soccer"}>Fotboll</MenuItem>
                <MenuItem value={"basket"}>Basket</MenuItem>
                <MenuItem value={"floorball"}>Innebandy</MenuItem>
              </Select>
            </FormControl>
          )}
          {!isTrainer && <Autocomplete
            disablePortal
            disabled={club.clubType === ""}
            options={districts} 
            onChange={(event, value) => setDistrict(value?.value || "")}
            renderInput={(params) => (
              <TextField {...params} variant="standard" label="Välj District"  fullWidth />
            )}
            fullWidth
            sx={{ marginTop: 2 }}
          />}
          <Autocomplete
            disablePortal
            disabled={district === "" && (!isTrainer || !club.clubType)}
            options={isTrainer ? takenClubs : clubs} 
            onChange={(event, value) => {setClub({...club, id: value?.value || "", clubName: value?.label || ""}); setUser({...user, activeClub: value?.value || ""})}}
            renderInput={(params) => (
              <TextField {...params} variant="standard" label="Välj Club" fullWidth />
            )}
            fullWidth
            sx={{ marginTop: 2 }}
          />
          {club.id !== "" && !isTrainer && 
            (!clubTaken ?
            <Stack direction={"row"} width="100" spacing={2} alignItems={"center"} padding={2}>
              <Typography>
                Pris: 
              </Typography>
              {price === 0 ? <LinearProgress sx={{ width: 40}} /> : <span>{price}kr</span>}
            </Stack> :
            <Typography>
              Klubb är redan registrerad, skapar du konto skickas automatiskt en inbjudan till klubben. Meddela oss om något är fel. 
            </Typography>)
          }
        </Stack>
      }
      {!showSignIn && 
        <FormGroup>
          <FormControlLabel 
            control={<Checkbox />} 
            onChange={(e, val) => setGeneralPolicy(val)}
            label={
              <Typography noWrap>
                Jag godkänner de&nbsp;
                <Link onClick={(e) => {
                    e.preventDefault(); // Prevent checkbox toggle
                    setPolicy("/Användarvillkor_Föreningar.pdf");
                  }}
                  >
                    almänna användarvillkoren
                </Link>
              </Typography>
            }
          />
          <FormControlLabel 
            control={<Checkbox />} 
            onChange={(e, val) => setIntegrity(val)}
            label={
              <Typography noWrap>
                Jag godkänner&nbsp;
                <Link onClick={(e) => {
                    e.preventDefault(); // Prevent checkbox toggle
                    setPolicy("/Integritetspolicy_för_Reffi.pdf");
                  }}
                > 
                  integritetspolicyn
                </Link>
              </Typography>
            }
          />
      </FormGroup>}
      <Modal
        open={policy !== ""}
        onClose={() => setPolicy("")}
        aria-labelledby="policy-modal-title"
        aria-describedby="policy-modal-description"
      >
        <Box sx={{  
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '60%', // adjust the width
            height: '80%', // adjust the height
            boxShadow: 24,
          }}>
          {/* Embed PDF or DOC */}
          <iframe
            src={policy}
            width="100%"
            height="100%"
            title="General User Policy"
          ></iframe>
        </Box>
      </Modal>
      {showSignIn && <Button onClick={resetPassword}>Glömt lösenord?</Button>}
      <Button disabled={!showSignIn && (!filledOut || password !== repeatPassword)} onClick={handleClick} variant="contained" >{showSignIn ? "Logga in" : "Skapa konto"}</Button>
    </Stack>
  )
}