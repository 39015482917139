export var matchType = function (match) {
    if (match.periodLength === 180)
        return 3;
    if (match.periodLength === 900)
        return 5;
    if (match.periodLength === 1200)
        return 7;
    if (match.periodLength === 1500)
        return 9;
    if (match.periodLength === 2400)
        return 11;
    return 0;
};
