import { Colors, formatDate, formatTime, Match, MatchInvite, paths } from "@monorepo/shared";
import { Button, Dialog, DialogContent, Divider, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Paper, Popover, Stack, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tabs } from "@mui/material";
import React from "react";
import { useOutletContext } from "react-router-dom";
import { useClubState } from "../../clubStateContext";
import { DataGrid, GridColDef, GridRowSelectionModel } from "@mui/x-data-grid";
import AutorenewIcon from '@mui/icons-material/Autorenew';
import ChecklistIcon from '@mui/icons-material/Checklist';
import ImportExportIcon from '@mui/icons-material/ImportExport';
import { DateCalendar, DatePicker } from "@mui/x-date-pickers";
import styled from "@emotion/styled";
import 'dayjs/locale/sv'
import dayjs, { Dayjs,  } from 'dayjs';
import { collection, doc, updateDoc } from "firebase/firestore";
import { db } from "config/firebase";

const PaymentsTab = ({
  value,
  setValue,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  markAsPaid,
}: {
  value: number,
  setValue: (val: number) => void,
  startDate: Dayjs | null,
  endDate: Dayjs | null,
  setStartDate: (date: Dayjs | null) => void,
  setEndDate: (date: Dayjs | null) => void,
  markAsPaid: () => void
}) => {
  const [anchorElStart, setAnchorElStart] = React.useState<null | HTMLElement>(null);
  const [anchorElEnd, setAnchorElEnd] = React.useState<null | HTMLElement>(null);

  const openStartPopover = Boolean(anchorElStart);
  const openEndPopover = Boolean(anchorElEnd);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <List>
      <Tabs
        orientation="vertical"
        value={value}
        onChange={handleChange}
        textColor="secondary"
        indicatorColor="secondary"
      >
        <Tab label="Obetalt" sx={{ color: Colors.darkBlue }} />
        <Tab label="Bokföringsrapport" sx={{ color: Colors.darkBlue }} />
        <Tab label="Skatterapport" sx={{ color: Colors.darkBlue }} />
      </Tabs>
      <Divider />
      {value === 0 && (
        <div>
          <ListItem>
            <ListItemButton disabled component="button" onClick={() => {}}>
              <ListItemIcon><AutorenewIcon color="secondary" /></ListItemIcon>
              <ListItemText primary="Generera BankFil" sx={{ textAlign: "center", color: Colors.white }} />
            </ListItemButton>
          </ListItem>
          <ListItem>
            <ListItemButton onClick={markAsPaid} component="button">
              <ListItemIcon><ChecklistIcon color="secondary" /></ListItemIcon>
              <ListItemText primary="Markera som betalda" sx={{ textAlign: "center", color: Colors.white }} />
            </ListItemButton>
          </ListItem>
        </div>
      )}
      {value !== 0 && (
        <div>
          <ListItem>
            <Button
              variant="contained"
              fullWidth
              onClick={(event) => setAnchorElStart(event.currentTarget)}
              >
              {startDate ? `Från: ${formatDate(startDate.toDate().toISOString())}` : "Start datum"}
            </Button>
            <Popover
                open={openStartPopover}
                anchorEl={anchorElStart}
                onClose={() => setAnchorElStart(null)}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
              >
                <DateCalendar
                  value={startDate}
                  onChange={(newDate) => {
                    setStartDate(newDate);
                    setAnchorElStart(null);
                  }}
                />
              </Popover>
          </ListItem>
          <ListItem>
            <Button
              variant="contained"
              fullWidth
              onClick={(event) => setAnchorElEnd(event.currentTarget)}
              >
              {endDate ? `Till:  ${formatDate(endDate.toDate().toISOString())}` : "Slut datum"}
            </Button>
            <Popover
                open={openEndPopover}
                anchorEl={anchorElEnd}
                onClose={() => setAnchorElEnd(null)}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
              >
                <DateCalendar
                  value={endDate}
                  onChange={(newDate) => {
                    setEndDate(newDate);
                    setAnchorElEnd(null);
                  }}
                />
              </Popover>
          </ListItem>
          <ListItem>
            <ListItemButton disabled component="button" onClick={() => {}}>
              <ListItemIcon><ImportExportIcon color="secondary" /></ListItemIcon>
              <ListItemText primary="Exportera" sx={{ textAlign: "center", color: Colors.white }} />
            </ListItemButton>
          </ListItem>
        </div>
      )}
    </List>
  );
};
const unpaidColumns: GridColDef[] = [
  { field: 'id', headerName: '', width: 30, },
  { field: 'name', headerName: 'Namn', width: 200 },
  { field: 'phone', headerName: 'Telefon', flex: 1 },
  { field: 'time', headerName: 'Datum', flex: 1 },
  { field: 'division', headerName: 'Division', flex: 2 },
  { field: 'match', headerName: 'Match', flex: 2 },
  { field: 'accountNumber', headerName: 'Bank nummer', flex: 1 },
  { field: 'compensation', headerName: 'Ersättning', align: "right", width: 100},
];

const accountingColumns: GridColDef[] = [
  { field: 'id', headerName: '', width: 30, },
  { field: 'match', headerName: 'Match', flex: 2 },
  { field: 'name', headerName: 'Domare', width: 200 },
  { field: 'time', headerName: 'Datum', flex: 1 },
  { field: 'compensation', headerName: 'Belopp', align: "right", width: 100},
];

const taxColumns: GridColDef[] = [
  { field: 'id', headerName: '', width: 30, },
  { field: "socialSecurityNumber", headerName: "Personnummer", flex: 1 },
  { field: 'name', headerName: 'Domare', width: 200 },
  { field: 'compensation', headerName: 'Belopp', align: "right", width: 100},
];

type MatchWithRefereeInfo = Match & MatchInvite & { refereeId: string, accountNumber: string, phoneNumber: string };


export const Payments = () => {
  const { club, matches, referees } = useClubState();
  const { setTitle, setTabSettings } = useOutletContext<{
    setTitle: React.Dispatch<React.SetStateAction<string>>,
    setTabSettings: React.Dispatch<React.SetStateAction<React.ReactNode>>,
  }>();

  const [currentTab, setCurrentTab] = React.useState(0);

  const [unpaid, setUnpaid] = React.useState<MatchWithRefereeInfo[]>([]);
  const [paid, setPaid] = React.useState<MatchWithRefereeInfo[]>([]);

  const [selectedRows, setSelectedRows] = React.useState<GridRowSelectionModel>([]);

  const [startDate, setStartDate] = React.useState<Dayjs | null>(dayjs().subtract(30, 'day'));
  const [endDate, setEndDate] = React.useState<Dayjs | null>(dayjs());

  const markAsPaid = React.useCallback(() => {
    if (!club) return;

    const clubRef = doc(db, paths.clubs, club.id!);
    const matchesRef = collection(clubRef, paths.matches);

    selectedRows.forEach(async (selectedRowId) => {
      const match = unpaid[Number(selectedRowId)];
      match.invites![match.refereeId] = {
        ...match.invites![match.refereeId],
        status: 'paid',
        paid: {
          amount: match.compensation! || 0,
          date: new Date().toISOString(),
        },
      };
      await updateDoc(doc(matchesRef, match.id?.toString()), {
        invites: match.invites,
      });
    });

    setSelectedRows([]);
  }, [club, selectedRows, unpaid]);

  React.useEffect(() => {
    setTabSettings(
      <PaymentsTab
        markAsPaid={markAsPaid}
        value={currentTab}
        setValue={setCurrentTab}
        startDate={startDate}
        setStartDate={setStartDate}
        endDate={endDate}
        setEndDate={setEndDate}
      />
    );
  }, [setTitle, setTabSettings, currentTab, startDate, endDate, markAsPaid]);
  
  React.useEffect(() => {
    let title = "Obetalt";
    if (currentTab === 1) title = "Bokföringsrapport";
    if (currentTab === 2) title = "Skatterapport";

    setSelectedRows([])
    setTitle(title);
  }, [setTitle, currentTab])

  React.useEffect(() => {
    const payments = matches
      .filter(match => new Date(match.time) < new Date())
      .reduce((acc: MatchWithRefereeInfo[], match: Match) => {
        const invitesArray = Object.entries(match.invites || {})
          .map(([refereeId, invite]) => {
            const referee = referees.find(referee => referee.id === refereeId)!;
            const accountNumber = referee.clearingNumber + "-" + referee.accountNumber;
            return ({...match, ...invite, refereeId, accountNumber, phoneNumber: referee.phoneNumber, socialSecurityNumber: referee.socialSecurityNumber})
          });
        return [...acc, ...invitesArray];
      }, []);
      
    const unpaidMatches = payments.filter((match) => match.status === 'done');
    const paidMatches = payments.filter((match) => match.status === 'paid');

    setUnpaid(unpaidMatches);
    setPaid(paidMatches);
  }, [matches, club]);



  const filterBetweenDates = (match: MatchWithRefereeInfo) => {
    const matchDate = dayjs(match.time)
    if (!endDate || !startDate) return false
    return matchDate <= endDate && matchDate >= startDate
  }

  const getColumns = () => {
    switch (currentTab) {
      case 0:
        return unpaidColumns;
      case 1:
        return accountingColumns;
      case 2:
        return taxColumns;
      default: 
        return []
    }
  }

  const getRows = () => {
    switch (currentTab) {
      case 0:
        return unpaid.map((match, i) => (
          {
            ...match,
            time: formatTime(match.time),
            compensation: match.compensation + "kr",
            match: match.home + "-" + match.away,
            id: i,
          }
        ))
      case 1:
        return paid.filter(filterBetweenDates).map((match, i) => {
          const paymentDetails = match.invites![match.refereeId].paid!;
          return {
            ...match,
            match: match.home + "-" + match.away,
            compensation: paymentDetails.amount + "kr",
            time: formatTime(paymentDetails.date),
            id: i,
          }
        })
      case 2:
        return paid.filter(filterBetweenDates).map((match, i) => {
          const paymentDetails = match.invites![match.refereeId].paid!;
          return {
            ...match,
            compensation: paymentDetails.amount + "kr",
            id: i,
          }
        })
      default: 
        return []
    }
  }

  return (
    <Paper sx={{ overflow: "hidden", height: "100%" }} variant="outlined">
      <DataGrid
        rows={getRows()}
        columns={getColumns()}
        pageSizeOptions={[5, 10]}
        checkboxSelection={!currentTab}
        onRowSelectionModelChange={(newSelection) => {
          setSelectedRows(newSelection);
        }}
        sx={{ border: 0 }}
      />
    </Paper>
  )
}