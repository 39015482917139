import React, { useState, useEffect } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TextField, Button, Box, Typography, Stack, IconButton, Toolbar, Divider, Link, Chip, Tooltip, Drawer, Tabs, List, Tab, ListItemButton, ListItem, ListItemText, ListItemIcon, LinearProgress, CircularProgress } from '@mui/material';
import { useClubState } from '../../clubStateContext';
import { Club, Colors, emptyMatch, filterComing, filterPlayed, formatTime, Invitation, Match, MatchInvite, matchType, paths, Referee, refereeStatus, sortByDate } from '@monorepo/shared';
import { MatchEditor, refereeChip } from './MatchEditor';
import { useOutletContext } from 'react-router-dom';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import { db } from 'config/firebase';
import { collection, doc, setDoc, updateDoc, writeBatch } from 'firebase/firestore';



export const TrainerSchedule = ({setTitle, setTabSettings}: {setTitle: React.Dispatch<React.SetStateAction<string>>, setTabSettings: React.Dispatch<React.SetStateAction<React.ReactNode>>}) => {
  const {club, matches, referees} = useClubState();
  const [coming, setComing] = useState<Match[]>([]);

  const formatMatches = React.useCallback(() => {
    const upcomingMatches = matches.filter(filterComing).sort(sortByDate);
  
    setComing(upcomingMatches);
  }, [matches])



  useEffect(() => {
    setTitle(!club ? "Saknar tillgång till Klubb" : "Kommande Matcher");
    setTabSettings(null)
  }, [setTitle, club, setTabSettings]);

  useEffect(() => {
    formatMatches();
  }, [matches, formatMatches]);

  const sortedInvites = (invites: Match["invites"]) => (
    Object.values(invites || {}).sort((a, b) => a.name > b.name ? 1 : -1)
  )

  const displayInviteField = (invites: Match["invites"], field: "name" | "phoneNumber") => (
    <Stack>
      {sortedInvites(invites).map((invite, i) => (
        <Typography key={i}>
          {invite[field]}
        </Typography>      
      ))}
    </Stack>
  )

  return (
    <Paper sx={{ overflow: "hidden", height: "100%" }} elevation={24}>
      <TableContainer sx={{ height: "100%", maxHeight: "100%", overflowY: "auto" }} component={Paper}>
        <Table stickyHeader size="small">
          <TableHead>
            <TableRow>
              {["", "Tid", "Plats", "Lag", "Division", "Domare", "Telefon"].map(title => (
                <TableCell key={title} sx={{ whiteSpace: 'nowrap', bgcolor: Colors.lightGrey}}><span style={{fontWeight: "bolder"}}>{title}</span></TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody >
            {coming.map((match, i) => (
              <TableRow  key={i}>
                <TableCell sx={{ whiteSpace: 'nowrap' }}>{i}</TableCell>
                <TableCell sx={{ whiteSpace: 'nowrap' }}>{formatTime(match.time)}</TableCell>
                <TableCell sx={{ whiteSpace: 'nowrap' }}>{match.place}</TableCell>
                <TableCell sx={{ whiteSpace: 'nowrap' }}>
                  <Stack direction="column">
                    <span>{match.home}</span>
                    <span>{match.away}</span>
                  </Stack>
                </TableCell>
                <TableCell sx={{ whiteSpace: 'nowrap' }}>{match.division}</TableCell>
                <TableCell sx={{ whiteSpace: 'nowrap' }}>{displayInviteField(match.invites, "name")}</TableCell>
                <TableCell sx={{ whiteSpace: 'nowrap' }}>{displayInviteField(match.invites, "phoneNumber")}</TableCell>

              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};