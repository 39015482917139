import React from "react";
import { useClubState } from "../../clubStateContext";
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Chip,
  Divider,
  Fade,
  FormControl,
  List,
  Modal,
  Paper,
  Stack,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  TextField,
  Typography
} from "@mui/material";
import { useOutletContext } from "react-router-dom";
import { Club, Colors, Invitation, paths, Referee, User } from "@monorepo/shared";
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { db } from "config/firebase";
import { deleteDoc, doc, updateDoc } from "firebase/firestore";
import { style } from "./MatchEditor";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const declineInvitation = async (id?: string, clubId?: string) => {
  if (!clubId || !id) return;
  const clubRef = doc(db, paths.clubs, clubId);
  const invitationRef = doc(clubRef, paths.invitations, id)
  await updateDoc(invitationRef, {status: "denied"});
}


const addUser = async (id: string, clubId?: string) => {
  if (!clubId) return;
  const clubRef = doc(db, paths.clubs, clubId);
  const invitationRef = doc(clubRef, paths.invitations, id)
  await updateDoc(invitationRef, {status: "accepted"});
}

const updatePermissions = async (user: Invitation, clubId?: string) => {
  if (!clubId || !user.id) return;
  const clubRef = doc(db, paths.clubs, clubId);
  const invitationRef = doc(clubRef, paths.invitations, user.id)
  await updateDoc(invitationRef, user);
}
const GenericTab = ({ value, setValue, labels, showBanned, setShowBanned }: { value: number, setValue: (val: number) => void, labels: string[], setShowBanned: () => void, showBanned: boolean }) => {
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <List>
      <Tabs orientation="vertical" value={value} onChange={handleChange} textColor='secondary' indicatorColor='secondary'>
        {labels.map((label, i) => (
          <Tab key={i} label={label} sx={{ color: Colors.darkBlue }} disabled={label === "Chatt"} />
        ))}
      </Tabs>
      <Divider />
      {false && <Button variant="contained" onClick={setShowBanned}>{showBanned ? "Visa blockerade" : "Göm blockerade"}</Button>}
    </List>
  );
};

const GenericTable = ({
  data,
  columns,
  onRowClick
}: {
  data: Invitation[],
  columns: string[],
  onRowClick: (item: Invitation) => void
}) => (
  <TableContainer sx={{ maxHeight: "100%", overflowY: "auto" }} component={Paper}>
    <Table stickyHeader>
      <TableHead>
        <TableRow>
          <TableCell sx={{ whiteSpace: 'nowrap' }}></TableCell>
          {columns.map((col) => <TableCell key={col} sx={{ whiteSpace: 'nowrap' }}>{col}</TableCell>)}
        </TableRow>
      </TableHead>
      <TableBody>
        {data.map((item, i) => (
          <TableRow key={i} hover onClick={() => onRowClick(item)} sx={{ cursor: 'pointer' }}>
            <TableCell>{i}</TableCell>
            <TableCell>{item.name}</TableCell>
            <TableCell sx={{ whiteSpace: 'nowrap' }}>
              {"permissions" in item ? 
                (item.permissions || []).map((permission, i) => <Chip key={i} label={permission + "-" + permission} style={{margin: 2}}/>)
                  :
                item.role
              }
            
            </TableCell>
            <TableCell>{item.phoneNumber}</TableCell>
            <TableCell>{item.email}</TableCell>
            {"socialSecurityNumber" in item && <TableCell>{item.socialSecurityNumber}</TableCell>}
            {item.status === "accepted" && <TableCell>{item.bank}</TableCell>}
            {item.status === "accepted" && <TableCell>{item.clearingNumber}-{item.accountNumber}</TableCell>}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </TableContainer>
);

const GenericModal = ({
  open,
  onClose,
  title,
  children
}: {
  open: boolean,
  onClose: () => void,
  title: string,
  children: React.ReactNode
}) => (
  <Modal disableAutoFocus open={open} onClose={onClose}>
    <Fade in={open}>
      <Paper sx={style} >
        <Typography variant="h4" width={350} padding={"10px"}>{title}</Typography>
        <Stack>
          {children}
        </Stack>
      </Paper>
    </Fade>
  </Modal>
);

export const Referees = () => {
  const { club, invitations } = useClubState();
  const { setTitle, setTabSettings } = useOutletContext<{ setTitle: React.Dispatch<React.SetStateAction<string>>, setTabSettings: React.Dispatch<React.SetStateAction<React.ReactNode>> }>();

  const [currentTab, setCurrentTab] = React.useState(0);
  const [selectedUser, setSelectedUser] = React.useState<Invitation | undefined>(undefined);

  const refereeColumns = ["Namn", "Dömer", "Telefon", "Mail", "Personnummer", "Bank", "Bankuppgifter"];
  const userColumns = ["Namn", "Roll", "Telefon", "Mail"];

  const [invited, setInvited] = React.useState<Invitation[]>([]);
  const [accepted, setAccepted] = React.useState<Invitation[]>([]);

  const [showBanned, setShowBanned] = React.useState(false);

  const handlePermissionChange = (event: any, newValue: string[]) => {
    setSelectedUser((prev: any) => prev && { ...prev, permissions: newValue.map(val => parseInt(val)) });
  };


  React.useEffect(() => {
    const users = invitations.filter(user => currentTab === 0 ? user.role === "referee" : user.role !== "referee")
    setAccepted(users.filter(user => user.status === "accepted"))
    setInvited(users.filter(user => user.status === "invited"))

  }, [invitations, currentTab])

  React.useEffect(() => {
    const labels = ["Domare", "Tränare", "Chatt"];
    setTitle(labels[currentTab]);
    setTabSettings(<GenericTab value={currentTab} setShowBanned={() => setShowBanned(false)} showBanned={showBanned} setValue={setCurrentTab} labels={labels} />);
  }, [setTitle, setTabSettings, currentTab, showBanned, setShowBanned]);

  return (
    <Box sx={{ overflow: "hidden", height: "100%" }}>
      <GenericModal open={!!selectedUser} onClose={() => setSelectedUser(undefined)} title={selectedUser?.name || ""}>
        {currentTab === 0 && selectedUser && 'permissions' in selectedUser && <FormControl sx={{ m: 1, width: 300 }}>
          <Autocomplete
            multiple
            fullWidth
            id="checkboxes-permissions"
            options={["3", "5", "7", "9", "11"]}
            sx={{width: 350}}
            disableCloseOnSelect
            value={(selectedUser.permissions || []).map(val => (val).toString()) || []}
            onChange={handlePermissionChange}
            onBlur={() => updatePermissions(selectedUser, club?.id)}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox icon={icon} checkedIcon={checkedIcon} checked={selected} style={{ marginRight: 8 }} />
                {option}
              </li>
            )}
            renderInput={(params) => <TextField {...params} label="Dömer" />}
          />
        </FormControl>}
        {selectedUser?.status === "invited" ? <Stack direction={"row"}>
          <Button onClick={() => {declineInvitation(selectedUser.id!, club?.id); setSelectedUser(undefined)}}>Neka</Button>
          <Button onClick={() => {addUser(selectedUser.id!, club?.id,); setSelectedUser(undefined)}}>Acceptera</Button>
        </Stack>:
        <Button onClick={() => {declineInvitation(selectedUser?.id || undefined, club?.id); setSelectedUser(undefined)}}>Ta bort</Button>
        }
      </GenericModal>

      {invited.length > 0 && (
        <Paper sx={{ marginBottom: 5 }}>
          <Typography variant="h4" padding={2}>Nya Förfrågningar</Typography>
          <Divider />
          <GenericTable
            data={invited}
            columns={currentTab === 0 ? refereeColumns : userColumns}
            onRowClick={(user) => setSelectedUser(user)}
          />
        </Paper>
      )}

      <Paper>
        <GenericTable
          data={accepted}
          columns={currentTab === 0 ? refereeColumns : userColumns}
          onRowClick={(user) => setSelectedUser(user)}
        />
      </Paper>
    </Box>
  );
};
