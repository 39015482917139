import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import { Avatar, Container, Drawer, List, ListItem, ListItemButton, ListItemIcon, Stack, Tab, Tabs, TextField, Link, Card, CardMedia, CardContent, CardActions, Divider, Grid2, Menu, MenuItem } from '@mui/material';
import { Colors, paths } from "@monorepo/shared";
import { AuthDrawer } from './Drawer';
import { addDoc, collection, Timestamp } from 'firebase/firestore';
import { db } from 'config/firebase';




export const LandingPage = () =>  {
  const [drawer, setDrawer] = React.useState(0);
  const [screenHeight, setScreenHeight] = React.useState(window.innerHeight);
  const [screenWidth, setScreenWidth] = React.useState(window.innerWidth);
  const [smallScreen, setSmallScreen] = React.useState(window.innerWidth < 930);

  const [contactInfo, setContactInfo] = React.useState({name: "", topic: "", email: "", message: ""})

  const clubsRef = React.useRef<HTMLElement>(null);
  const refereeRef = React.useRef<HTMLElement>(null);
  const aboutRef = React.useRef<HTMLElement>(null);
  const contactRef = React.useRef<HTMLElement>(null);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  React.useEffect(() => {
    const handleResize = () => {
      setScreenHeight(window.innerHeight);
      setScreenWidth(window.innerWidth);
      setSmallScreen(window.innerWidth < 930)
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  
  const handleScroll = (sectionRef: React.RefObject<HTMLElement>) => {
    sectionRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  async function saveMessage() {
    if (Object.values(contactInfo).some(val => val === "")) {
      return; 
    }
    const messageRef = collection(db, paths.messages);
    const data = {
      ...contactInfo,
      timeStamp: Timestamp.fromDate(new Date())
    };    
    console.log(data)
    await addDoc(messageRef, data);
  }

  return (
    <Box overflow={"hidden"} sx={{bgcolor: Colors.lightGrey}}>
      <Drawer anchor="right" open={drawer !== 0} onClose={() => setDrawer(0)}>
        {drawer === 1 ? <AuthDrawer signIn onClose={() => setDrawer(0)}/> : <AuthDrawer onClose={() => setDrawer(0)}/>}
      </Drawer>
      <AppBar position="static" color='primary'  >
        <Toolbar >
          <Avatar sx={{ width: 48, height: 48 }} src={require("./images/icon.png")}></Avatar>
          <Typography variant="h4" fontStyle={"italic"} component="div" > 
            REFFI
          </Typography>                   
          {!smallScreen ? <div style={{width: "100%", flexDirection: "row", display: "flex", justifyContent: "space-between"}}>
              <div style={{ paddingLeft: 15, flexGrow: 1, paddingTop: 3, display: 'flex', gap: '15px' }}>
                <Button color="inherit" onClick={() => handleScroll(clubsRef)}>För föreningar</Button>
                <Button color="inherit" onClick={() => handleScroll(refereeRef)}>För domare</Button>
                <Button color="inherit" onClick={() => handleScroll(aboutRef)}>Om oss</Button>
                <Button color="inherit" onClick={() => handleScroll(contactRef)}>Kontakt</Button>
              </div>
              <div style={{gap: 10, flexDirection: "row", display: "flex"}}>
                <Button variant="contained" onClick={() => setDrawer(1)}>Logga In</Button>
                <Button variant="contained" onClick={() => setDrawer(2)}>Kom Igång!</Button>
              </div> 
            </div>
            : <div style={{width: "100%", flexDirection: "row", display: "flex", justifyContent: "flex-end"}} >
                          <div>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem onClick={() => {setDrawer(1); handleClose()}}>Logga In</MenuItem>
                <MenuItem onClick={() => {setDrawer(2); handleClose()}}>Kom Igång</MenuItem>
              </Menu>
            </div>
            </div>}
        </Toolbar>
      </AppBar>
      <Stack sx={{ justifyContent: 'center', alignItems: 'center' }}>
        <img
          src={require("./images/Reffi_Socials_Bild1.png")}
          alt=""
          style={{padding: 50, paddingTop: 0, backgroundColor: Colors.primary, width: '100%', height: 'auto', maxHeight: '95vh', objectFit: "cover" }}
        />
        <Stack direction="row" sx={{padding: 5, paddingTop: 10, justifyContent: "space-around", width: "100%"}}>
          <Typography variant="h4" align={"center"}>
            <span style={{ color: Colors.primary }}>Slösa </span>
            <span style={{ textDecoration: 'underline' }}>minuter.</span>
          </Typography>
          <Typography variant="h4" align={"center"}>
            <span style={{ color: Colors.primary}}>Tjäna </span>
            <span style={{ textDecoration: 'underline'}}>timmar.</span>
          </Typography>
        </Stack>
        <svg height={screenHeight / 5} width={screenWidth}>
          <path d={`M-100 0 Q ${screenWidth / 2} ${screenHeight / 5} ${screenWidth + 100} 0` }
            strokeWidth="10" 
            stroke={Colors.primary}
            fill="transparent" />
        </svg>
      </Stack>
      <Typography variant="h2" padding={10} paddingLeft={"10%"} ref={clubsRef}>
        <span style={{ color: Colors.primary}}>För Föreningar </span>
      </Typography>
      <Stack direction={"row"} padding={"5%"} maxWidth={"100%"} overflow={"auto"} paddingTop={0} spacing={3}>
        <InfoCard image={require("./images/Välj_Bland_Flera_Sporter.png")} title="Välj bland flera sporter" description="Välj den idrott som just eran förening utövar. Vi utökar ständigt med nya sporter som kan använda vårat system, då målet är att alla ska tjäna tid, för att sen återinvestera tiden i era barn och ungdomar." />
        <InfoCard image={require("./images/3.png")} title="Smart schemaläggning av domare" description="Med våran smarta schemaläggning kan ni som förening enkelt hantera vilka domare som ska dömma, och vilka arvoden de kommer att få." />
        <InfoCard image={require("./images/4.png")} title="Enkelt att administrera domare" description="Vi strävar efter att det ska bli så enkelt som möjligt för er att administrera era träningsmatcher, seriematcher och cupspelsmatcher. Reffi är ett verktyg för er att enkelt spara in tid och pengar på administrativa uppgifter." />
      </Stack>
      <Typography variant="h2" padding={10} paddingLeft={"10%"} ref={refereeRef}>
        <span style={{ color: Colors.primary}}>För Domare </span>
      </Typography>
      <Stack direction={"row"} padding={"5%"} maxWidth={"100%"} overflow={"auto"} paddingTop={0} spacing={3}>
        <InfoCard image={require("./images/2.png")} title="Se dina matcher i appen" description="Domare kan nu enkelt följa och se sina matcher direkt i mobilappen. Appen ger en smidig översikt över kommande matcher, med möjlighet att granska detaljer och hålla sig uppdaterad. Funktionen gör det enklare för domare att planera och förbereda sig inför matcherna, oavsett var de befinner sig." />
        <InfoCard image={require("./images/Byt_Enkelt_Matcher_Med_Andra_Domare.png")} title="Byt enkelt matcher med andra domare" description="Domare kan enkelt byta matcher med varandra direkt i systemet. Funktionen gör det smidigt att hantera ändringar i schemat, så att domare kan anpassa sig efter sina behov. Genom att byta matcher sinsemellan kan domarna se till att alla matcher täcks, samtidigt som de får flexibilitet i sitt eget schema." />
        <InfoCard image={require("./images/1.png")} title="Håll koll på dina arvoden" description="Domare kan enkelt hålla koll på sina arvoden direkt i appen. Med en tydlig översikt över utbetalda och kommande ersättningar får domarna full kontroll på sin ekonomi. Funktionen gör det lätt att följa upp och se hur mycket som betalats ut för varje match." />
      </Stack>
      <Typography variant="h2" padding={10} paddingLeft={"10%"}>
        <span style={{ color: Colors.primary}}>Priser </span>
      </Typography>
      <Stack direction={"row"} padding={"5%"} maxWidth={"100%"} overflow={"auto"} paddingTop={0} spacing={3}>
        <ActionCard image={require("./images/Bild_Pris_249.png")} onClick={() => {setDrawer(2)}} price="249kr" title="Mindre än 20 matcher" description="Tjänsten erbjuder tre prisnivåer beroende på antalet matcher per månad. För föreningar som har mellan 0 och 20 matcher per månad kostar tjänsten 249 kr, vilket ger ett prisvärt alternativ för mindre verksamheter."/>
        <ActionCard image={require("./images/Bild_Pris_399.png")} onClick={() => {setDrawer(2)}} price="399kr" title="Mindre än 70 matcher" description="För föreningar med 20 till 69 matcher per månad är priset 399 kr. Detta passar föreningar med ett något större matchschema och ger full tillgång till alla funktioner för att hantera domare och matcher effektivt."/>
        <ActionCard image={require("./images/70_plus.png")} onClick={() => {setDrawer(2)}} price="799kr" title="Mer än 70 matcher" description="De största föreningarna, med över 70 matcher per månad, betalar 799 kr. Detta alternativ ger den bästa lösningen för organisationer med hög matchvolym, och garanterar smidig administration oavsett omfattning."/>
      </Stack>
      <Box margin={"10%"} marginTop={20} ref={aboutRef}>
        <Stack direction={smallScreen ? "column" : "row"} padding={"6%"} justifyContent={"center"} spacing={2} >
            <img
              src={require("./images/Blue_Chairs.png")}
              alt=""
              style={{width: smallScreen ? '100%' : '50%', flex: 1, objectFit: "cover" }}
            />
            <Stack alignItems={"center"} justifyContent={"center"} padding={"5%"}>
              <Typography gutterBottom variant={smallScreen ? "h4" : "h2"} component="div" sx={{ marginBottom: 0 }}>
                <span style={{ color: Colors.primary }}>Av föreningsaktiva.</span>
              </Typography>
              <Typography gutterBottom variant={smallScreen ? "h4" : "h2"} component="div" sx={{ marginBottom: smallScreen ? 5 : 15 }}>
                <span style={{ color: Colors.primary }}>För föreningsaktiva.</span>
              </Typography>
              <Typography component="div" variant={smallScreen ? "h6" : "h5"} sx={{ color: 'text.secondary', marginBottom: 15}}>
                <span style={{ color: Colors.darkBlue }}>
                  Reffi är skapat av föreningsaktiva och domare för att underlätta vardagen i att bedriva föreningsverksamhet. Vi jobbar mot att automatisera flertal nödvändigheter i den dagliga verksamheten hos föreningar. I nuläget fokuserar vi främst med bokning.
                  schemaläggning och utbetalning för domare. Vi hjälper föreningar att administrera sina föreningsdomare.
                </span>
              </Typography>
              <Button size="large" color='primary' variant='outlined' onClick={() => setDrawer(2)} sx={{borderRadius: 30, alignSelf: "flex-start"}}>KOM IGÅNG!</Button>
            </Stack>  
        </Stack>
      </Box>
      <Stack marginTop={15} padding={5} spacing={5} alignItems={"center"}>
        <Typography gutterBottom variant={smallScreen ? "h5" : "h3"} component="div">
            <span style={{ color: Colors.primary }}>Följ utvecklingen på våra sociala medier</span>
        </Typography>
        <Stack alignItems={"center"} direction={"row"} justifyContent={"center"} spacing={1}>
          <img
            src={require("./images/facebook_reffi.png")}
            alt="Facebook"
            onClick={() => window.open("https://www.facebook.com/profile.php?id=61560848564067", "_blank")}
            style={{width: '30%', height: '100%', objectFit: "cover" }}
          />
          <img
            src={require("./images/instagram_reffi.png")}
            alt="Instagram"
            onClick={() => window.open("https://www.instagram.com/reffi.se/", "_blank")}
            style={{width: '30%', height: '100%', objectFit: "cover" }}
          />
          <img
            src={require("./images/linkedin_reffi.png")}
            alt="LinkedIn"
            onClick={() => window.open("https://www.linkedin.com/in/reffi-ab-48a400327/", "_blank")}
            style={{width: '30%', height: '100%', objectFit: "cover" }}
          />
        </Stack>
        <Stack alignItems={"center"} direction={"row"} justifyContent={"center"} padding={5} spacing={"10%"}>
          <Button size="large" color='primary' variant='outlined' onClick={() => window.open("https://www.facebook.com/profile.php?id=61560848564067", "_blank")} sx={{borderRadius: 30}}>Facebook</Button>
          <Button size="large" color='primary' variant='outlined' onClick={() => window.open("https://www.instagram.com/reffi.se/", "_blank")} sx={{borderRadius: 30}}>Instagram</Button>
          <Button size="large" color='primary' variant='outlined' onClick={() => window.open("https://www.linkedin.com/in/reffi-ab-48a400327/", "_blank")} sx={{borderRadius: 30}}>LinkedIn</Button>
        </Stack>
      </Stack>
      <Grid2 container padding={"10%"} spacing={0} rowSpacing={{xs: 5, md: 30}}>
        <Grid2 size={{xs: 12, md: 4}}>
          <Box>
            <Typography variant="h2" sx={{ color: Colors.primary, marginBottom: '20px' }} ref={contactRef}>
              Kontakta Oss!
            </Typography>
            <Typography variant="body1" sx={{ color: Colors.darkBlue, marginBottom: '10px' }}>
              Intresserad av att börja använda Reffi? Tveka inte med att kontakta oss om frågor och funderingar!
            </Typography>
            <Typography variant="body1" sx={{ color: Colors.darkBlue, marginBottom: '40px' }}>
              Om eran förening redan använder Reffi och har frågor kring systemet eller förbättringsförslag, hör av er till våran mail!
            </Typography>
          </Box>
        </Grid2>
        <Grid2 size={{xs: 0, md: 3}}> 
        </Grid2>
        <Grid2 size={{xs: 12, md: 5}}>
          <Stack spacing={3}>
            <Stack direction={"row"} justifyContent={"space-between"} spacing={5}>
              <TextField id="contactName" fullWidth label="Namn" onChange={e => setContactInfo({...contactInfo, name: e.target.value})} required/>
              <TextField id="contactFamilyName" fullWidth label="Ämne" onChange={e => setContactInfo({...contactInfo, topic: e.target.value})} required/>
            </Stack>
            <TextField id="contactMail" label="Mail" required onChange={e => setContactInfo({...contactInfo, email: e.target.value})} />
            <TextField id="contactMessage" label="Meddelande" required multiline rows={3} onChange={e => setContactInfo({...contactInfo, message: e.target.value})}/>
            <Button variant="contained" sx={{width: 100, borderRadius: 30}} onClick={saveMessage}>Skicka</Button>
          </Stack>
        </Grid2>
        {!smallScreen && <Grid2 size={4}>
          <Typography variant="h2" sx={{ color: Colors.primary, marginBottom: '20px' }}>
            Reffi
          </Typography>
        </Grid2>}
        <Grid2 size={{xs: 0, md: 3}}></Grid2>
        <Grid2 size={4}>
          <Stack direction={"row"} spacing={{xs: 8, md:15}} >
            <Stack>
              <Typography variant={smallScreen ? "h6" : "h5"} sx={{ color: Colors.darkBlue, marginBottom: '40px' }}>
                Adress
              </Typography>
              <Typography variant={smallScreen ? "h6" : "h5"} sx={{ color: Colors.darkBlue, marginBottom: '40px' }}>
                Omgången 411, 41280 Göteborg
              </Typography>
            </Stack>
            <Stack>
              <Typography variant={smallScreen ? "h6" : "h5"} sx={{ color: Colors.darkBlue, marginBottom: '40px' }}>
                Kontakt
              </Typography>              
              <Typography variant={smallScreen ? "h6" : "h5"} sx={{ color: Colors.darkBlue, marginBottom: '40px' }}>
                kontakt@reffi.se
              </Typography>
            </Stack>
          </Stack>
        </Grid2>
      </Grid2>
    </Box>
  );
}

const InfoCard = ({image, title, description}: {image: string, title: string, description: string}) => {
  return (
    <Card sx={{ width: "34%", minWidth: 300, height: "60vh"}}>
      <CardMedia
        component="img"
        sx={{ height: "70%" }}
        image={image}
      />
      <CardContent sx={{ height: "30%", flexDirection: 'column', justifyContent: "space-between" }}>
        <Typography gutterBottom variant="h5" component="div" sx={{ marginBottom: 2 }}>
          <span style={{ color: Colors.primary }}>{title}</span>
        </Typography>
        <Typography component="div" variant="body1" sx={{ color: 'text.secondary', flexGrow: 1, maxHeight: "150px", overflowY: "auto" }}>
          <span style={{ color: Colors.darkBlue }}>{description}</span>
        </Typography>
      </CardContent>
    </Card>
  )
}

const ActionCard = ({image, title, description, price, onClick}: {image: string, title: string, description: string, price: string, onClick: () => void}) => {
  return (
    <Card sx={{ width: "34%", minWidth: 300, height: "65vh"}}>
      <CardMedia
        component="img"
        sx={{ height: "50%" }}
        image={image}
      />
      <CardContent sx={{maxHeight: "50%", overflowY: "auto"}}>
        <Typography gutterBottom variant="h5" component="div" sx={{ marginBottom: 2 }}>
          <span style={{ color: Colors.primary }}>{title}</span>
        </Typography>
        <Typography gutterBottom variant="h4" component="div" sx={{ marginBottom: 2 }}>
          <span style={{ color: Colors.primary }}>{price}</span>
        </Typography>
        <Typography component="div" variant="h6" sx={{ color: 'text.secondary', flexGrow: 1 }}>
          <span style={{ color: Colors.darkBlue }}>{description}</span>
        </Typography>
      </CardContent>
      <CardActions sx={{height: "10%", justifySelf: "flex-end"}}>
        <Button onClick={onClick} color='primary' variant="contained" sx={{borderRadius: 30}}>Kom Igång!</Button>
      </CardActions>
    </Card>
  )
}
